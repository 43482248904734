<template>
    <div class="like_img_wrap">
        <div class="like_img"></div>
        <span class="like_count">251</span>
    </div>
</template>

<script>
export default {
    name: "LikeDefaultLayout"
}
</script>

<style scoped>

</style>